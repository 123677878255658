import React, { useState, useEffect } from 'react';
import DirectusSDK                    from '@directus/sdk-js';
import { useStaticQuery, graphql }    from 'gatsby';

import Spinner, { SpinnerContainer } from '../Spinner/Spinner';

function isBeforeToday(date) {
  const today = new Date();
  const jsDate = new Date(date);

  today.setHours(0, 0, 0, 0);
  jsDate.setHours(0, 0, 0, 0);

  return jsDate > today;
}

const LiveEvents = () => {
  const client = new DirectusSDK({
    url: process.env.GATSBY_API_ENDPOINT,
    project: 'announcements',
  });

  const project_name = useStaticQuery(query).site.siteMetadata.projectName;
  const [eventsCategoriesRequest, setEventsCategoriesRequest] = useState({ loading: false, data: null, error: null });

  useEffect(() => {
    const fetchData = async () => {
      setEventsCategoriesRequest({ loading: true });
      let categories;
      try {
        categories = await client.getItems('event_category', {
          status: 'published',
          'filter[project.domain]': process.env.NODE_ENV === 'development' ? 'development' : project_name,
          fields: '*.*'
        });
        setEventsCategoriesRequest({
          loading: false,
          data: categories.data,
        })
      } catch (e) {
        setEventsCategoriesRequest({ loading: false, error: e });
        console.log(e)
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  const { data: categories, loading: categoriesLoading } = eventsCategoriesRequest;

  return (
    <div>
      {categoriesLoading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {eventsCategoriesRequest.data && categories.map(category => (
        <>
          <h3>{category.display_name}</h3>
          <div dangerouslySetInnerHTML={{ __html: category.description }}/>
          <div>
            {category.events.map(event => {
              const active = isBeforeToday(event.event_end);
              if (event.status === 'published' && active) {
                return (
                  <div dangerouslySetInnerHTML={{ __html: event.content }}/>
                )
              }
            })}
          </div>
        </>
      ))}
    </div>
  )

};

export default LiveEvents;

const query = graphql`
    query {
        site {
            siteMetadata {
                projectName
            }
        }
    }
`;